
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import { UsuarioModel } from "@/models/UsuarioModel";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();

    const user = ref<UsuarioModel>(store.getters.usuarioLogado);

    const signOut = () => {
      store.dispatch(Actions.LOGOUT);
      localStorage.removeItem('lojaFixada')
    };

    function procuraAcesso(nivelCargo) {
      // console.log("procuraAcesso", nivelCargo)
      if (user.value.idCargo == 1) {
        return "Administrador";
      }
    }

    return {
      signOut,
      user,
      procuraAcesso,
    };
  },
});
