
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import AuthService from "@/services/AuthService";
import store from "@/store/index";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();

    let dominio_gestao = "localhost:8080";
    let tipo_conexao = "http";
    let link_v2 = "";
    let link_v3 = "../v3";

    if (dominio_gestao == "localhost:8080") {
      link_v2 = tipo_conexao + "://localhost/acampa-gestao-pra/v2";
    } else {
      link_v2 = "../v2";
    }

    const usuario = AuthService.getUsuario();
    const nivelCargo = usuario.nivelCargo;

    const menuHeaders = [
      {
        sectionTitle: "Dashboards",
        route: "/subscriptions1",
        nivelCargo: [100, 150],
        sub: [
          {
            heading: "One Page Report",
            route: "dashboard",
            svgIcon: "./media/icons/duotune/files/fil024.svg",
          },
          {
            heading: "Por Grupo",
            route: link_v2 + "/report_grupo.php",
            svgIcon: "./media/icons/duotune/graphs/gra010.svg",
          },
          {
            heading: "Por marca",
            route: link_v2 + "/report_marca.php",
            svgIcon: "./media/icons/duotune/technology/teh004.svg",
          },
          {
            heading: "Business Inteligence",
            route: link_v2 + "/dashboard.php",
            svgIcon: "./media/icons/duotune/technology/teh003.svg",
          },
        ],
      },
    ];

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const notificationRef = ref(store.getters.notificationsSession);

    store.watch(
      () => store.getters.notificationsSession,
      async () => {
        notificationRef.value = store.getters.notificationsSession;
      },
      { deep: true }
    );

    function goTo() {
      router.push("/oficina-digital/gerenciar");
    }

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      menuHeaders,
      translate,
      nivelCargo,
      link_v2,
      notificationRef,
      link_v3,
      goTo,
    };
  },
});
