import store from "@/store/index";

//comentado temporariamente
const DocMenuConfig = [
  {
    route: "/",
    pages: 
    [
      
      {
        heading: "Dashboard",
        route: "/dashboard",
        nivelCargo: [100, 150, 200, 300, 650],
        svgIcon: "./media/icons/duotune/graphs/gra008.svg",
      },
      {
        heading: "Dashboard",
        route: "/dashboardGerencia",
        nivelCargo: [400],
        svgIcon: "./media/icons/duotune/graphs/gra008.svg",
      },
      {
        heading: "Lojas",
        route: "/lojas",
        nivelCargo: [100, 150, 200],
        fontIcon: "fa-store",
        svgIcon: "./media/icons/duotune/general/store1.svg",
      },
      {
        heading: "Usuários",
        route: "/usuarios",
        nivelCargo: [100, 150, 200],
        svgIcon: "./media/icons/duotune/general/tools-duotone.svg",
      },
      {
        heading: "Atendimentos",
        route: "/lojas/atendimentos?",
        nivelCargo: [100, 150, 200],
        fontIcon: "fa-chart-area",
        svgIcon: "./media/icons/duotune/general/hands.svg",
      },
      {
        sectionTitle: "Cadastros",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/box.svg",
        sub: [
          {
            heading: "Grupos",
            route: "/cadastros/grupos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Bandeiras",
            route: "/bandeiras",
            nivelCargo: [100, 150],
          },
          {
            heading: "Lubrificantes",
            route: "/gestaoLubrificantes",
            nivelCargo: [100, 150],
          },
          {
            heading: "Fracionados",
            route: "/produtosFracionados",
            nivelCargo: [100, 150],
          },
          {
            heading: "Planos",
            route: "/planos",
            nivelCargo: [100, 150],
          }
        ],
      },
      {
        sectionTitle: "Gestão Técnica",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/cogs.svg",
        sub: [
          {
            heading: "Veículos",
            route: "/filtroVeiculos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Filtros",
            route: "/veiculos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Revisão de Veículos",
            route: "/veiculosRevisao",
            nivelCargo: [100, 150],
          },
          {
            heading: "Upload Filtros",
            route: "/planilhas",
            nivelCargo: [100, 150],
          },
          {
            heading: "Upload Veículos",
            route: "/planilhasVeiculos",
            nivelCargo: [100, 150],
          },
        ]

      },
      {
        sectionTitle: "Relatórios",
        nivelCargo: [100, 150, 200],
        svgIcon: "./media/icons/duotune/general/relatorio.svg",
        sub: [
          {
            heading: "Relatório Busca Placa",
            route: "/relatorios/buscaPlaca",
            nivelCargo: [100, 150],
          },
          {
            heading: "Visualização de custos",
            route: "/custosApp",
            nivelCargo: [100, 150],
          }
        ],
      },
      {
        sectionTitle: "Logs",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/gen005.svg",
        sub: [
          {
            heading: "Integração de Produtos",
            route: "/logs/produtos",
            nivelCargo: [100, 150],
          }
        ],
      },
    ],
  },
];

export default DocMenuConfig;
