
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import UserMenu from "@/layout/header/partials/UserMenu.vue";
import AuthService from "@/services/AuthService";
import store from "@/store/index";
import { version } from "@/../package.json";
import { UsuarioModel } from "@/models/UsuarioModel";

export default defineComponent({
  name: "KTMenu",
  components: { UserMenu },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const appVersion = version;

    const usuario: UsuarioModel = store.getters.usuarioLogado;
    const nivelCargo = usuario.idCargo;
    const userId = usuario.id;

    const menuHeaders = [
      {
        sectionTitle: "Dashboards",
        route: "/subscriptions1",
        nivelCargo: [2],
        sub: [
          {
            heading: "One Page Report",
            route: "dashboard",
            svgIcon: "./media/icons/duotune/files/fil024.svg",
          },
          {
            heading: "Por Grupo",
            route: "/report_grupo.php",
            svgIcon: "./media/icons/duotune/graphs/gra010.svg",
          },
          {
            heading: "Por marca",
            route: "/report_marca.php",
            svgIcon: "./media/icons/duotune/technology/teh004.svg",
          },
          {
            heading: "Business Inteligence",
            route: "/dashboard.php",
            svgIcon: "./media/icons/duotune/technology/teh003.svg",
          },
        ],
      },
    ];

    const menuHeadersWithoutIcon = [
      {
        sectionTitle: "Ações Rápidas",
        route: "/ações",
        nivelCargo: [-1, 2],
        sub: [
          {
            heading: "Lançamento individual",
            route: "/lancamentoadd.php",
          },
          {
            heading: "Lançamento por Grupo",
            route: "/lancamentoaddgrupomarca.php",
          },
          {
            heading: "Lançamento Lote Workflow",
            route: "/lancamentoaddlote_workflow.php",
          },
        ],
      },
    ];

    //estava dando erro com o true
    // function refresh() {
    //   location.reload(true);
    // }

    function refresh() {
      location.reload();
    }

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      menuHeaders,
      menuHeadersWithoutIcon,
      translate,
      nivelCargo,
      userId,
      appVersion,
      refresh,
    };
  },
});
