
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import store from "@/store/index";
import AuthService from "@/services/AuthService";
import router from "@/router";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const usuario = AuthService.getUsuario();
    const nivelCargo = usuario.nivelCargo;
    const showIcon = ref(false);
    let linkApp = ref("");

    onMounted(() => {
      ToggleComponent.reinitialization();
      ToggleComponent.bootstrap();
      // HtmlClass.initAside();
    });

    function rota() {
      router.push({ name: "login" });
    }

    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPod/i) ||
        (userAgent.match(/Macintosh/i) && navigator.maxTouchPoints == 5)
      ) {
        return "iOS";
      } else if (userAgent.match(/Android/i)) {
        return "Android";
      } else {
        return "web";
      }
    }

    function redirect() {
      let distribuidor = store.getters.layoutConfig(
        "distribuidor.distribuidor"
      );
      let dominio_gestao = store.getters.layoutConfig(
        "distribuidor.dominio_gestao"
      );
      if (dominio_gestao == "localhost:8080" || dominio_gestao == "localhost") {
        window.location.href = "http://localhost/acampa-gestao-pra/oferta";
      } else if (distribuidor == "homologacao") {
        window.location.href = "../oferta";
      } else {
        var tipo = getMobileOperatingSystem();
        if (tipo == "iOS") {
          let iphone_link = store.getters.layoutConfig(
            "distribuidor.link_loja_ios"
          );
          window.location.replace(iphone_link);
          //window.location.replace('itms-apps://apps.apple.com/us/app/acampa-oferta-digital-atria/id1451542707');
        } else if (tipo == "Android") {
          var android_link = store.getters.layoutConfig(
            "distribuidor.link_loja_android"
          );
          android_link = android_link.split("id=");
          window.location.replace(
            "intent:#Intent;scheme=https;package=" + android_link[1] + ";end"
          );
        } else {
          window.location.href = "../oferta";
        }
      }
    }

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      linkApp,
      showIcon,
      redirect,
      rota,
    };
  },
});
